import { render, staticRenderFns } from "./defaultVisa.vue?vue&type=template&id=2bd88e8a&"
import script from "./defaultVisa.vue?vue&type=script&lang=ts&"
export * from "./defaultVisa.vue?vue&type=script&lang=ts&"
import style0 from "./defaultVisa.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Sidebar: require('/root/workspace/com.jufair.vue_H3C2/components/Sidebar/index.vue').default,HeaderVisa: require('/root/workspace/com.jufair.vue_H3C2/components/HeaderVisa/index.vue').default,Footer2: require('/root/workspace/com.jufair.vue_H3C2/components/Footer2/index.vue').default,Join: require('/root/workspace/com.jufair.vue_H3C2/components/Join/index.vue').default})
